<template>
  <div v-if="device">
    <div class="container repair-container">
      <loading-state v-if="loading" title="Loading symptom codes"/>

      <!-- Search and Breadcrumb -->
      <template v-if="currentSymptomCode !== 'complete'">
        <!-- Search -->
        <div class="row mb-2">
          <div class="col-md-12">
            <label class="mt-2">Select the code {{ currentSymptomCode }} symptom</label>
          </div>
        </div>

        <!-- Breadcrumb -->
        <div class="card bg-transparent mb-3 text-muted text-sm" v-if="symptomCodeOne">
          <div>
            <span v-if="symptomCodeOne" @click="editSymptomCodeOne()">Code One: <strong
                class="text-underline cursor-pointer">{{ symptomCodeOne.title }}</strong></span>
            <span v-if="symptomCodeTwo" @click="editSymptomCodeTwo()"> &nbsp;/&nbsp; Code Two: <strong
                class="text-underline cursor-pointer">{{ symptomCodeTwo.title }}</strong></span>
            <span v-if="symptomCodeThree"> &nbsp;/&nbsp; Code Three: <strong
                class="text-underline">{{ symptomCodeThree.title }}</strong></span>
          </div>
        </div>
      </template>

      <!-- Code one -->
      <template v-if="!symptomCodeOne && !symptomCodeTwo && !symptomCodeThree">
        <div class="card-grid vertical">
          <div class="card-link-container" v-for="symptomOneCode in symptomOneCodes" :key="symptomOneCode.code">
            <div class="card card-body card-body-sm card-link" @click="setSymptomCodeOne(symptomOneCode)">
              {{ symptomOneCode.title }}
            </div>
          </div>
        </div>
      </template>

      <!-- Code two -->
      <template v-if="symptomCodeOne && !symptomCodeTwo && !symptomCodeThree">
        <div class="card-grid vertical">
          <div class="card-link-container" v-for="symptomTwoCode in symptomTwoCodes" :key="symptomTwoCode.code">
            <div class="card card-body card-body-sm card-link" @click="setSymptomCodeTwo(symptomTwoCode)">
              {{ symptomTwoCode.title }}
            </div>
          </div>
        </div>
      </template>

      <!-- Code three -->
      <template v-if="symptomCodeOne && symptomCodeTwo && !symptomCodeThree">
        <div class="card-grid vertical">
          <div class="card-link-container" v-for="symptomThreeCode in symptomThreeCodes" :key="symptomThreeCode.code">
            <div class="card card-body card-body-sm card-link" @click="completeSymptomCodes(symptomThreeCode)">
              {{ symptomThreeCode.title }}
            </div>
          </div>
        </div>
      </template>

      <!-- Confirmation -->
      <template v-if="currentSymptomCode === 'complete'">
        <h2>Selected symptom codes</h2>
        <ul class="list-group">
          <li class="list-group-item bg-secondary">
            <div class="d-flex">
              <div style="width: 120px;">
                Code One:
              </div>
              <strong>{{ symptomCodeOne.title }}</strong>
            </div>
            <button class="btn btn-info btn-sm" @click="editSymptomCodeOne()">Edit</button>
          </li>
          <li class="list-group-item bg-secondary">
            <div class="d-flex">
              <div style="width: 120px;">
                Code Two:
              </div>
              <strong>{{ symptomCodeTwo.title }}</strong>
            </div>
            <button class="btn btn-info btn-sm" @click="editSymptomCodeTwo()">Edit</button>
          </li>
          <li class="list-group-item bg-secondary">
            <div class="d-flex">
              <div style="width: 120px;">
                Code Three:
              </div>
              <strong>{{ symptomCodeThree.title }}</strong>
            </div>
            <button class="btn btn-info btn-sm" @click="editSymptomCodeThree()">Edit</button>
          </li>
        </ul>
      </template>

      <!-- Footer -->
      <fixed-footer
          v-if="(currentSymptomCode === 'complete' || isJobManager)"
          :class="{'active': footerActive}"
          :nextLabel="this.nextRepairStep($route.name).title"
          :nextRoute="this.nextRepairStep($route.name).routeName"
          :prevRoute="this.prevRepairStep($route.name).routeName">
      </fixed-footer>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  props: [
    'jobId',
    'deviceId'
  ],

  data() {
    return {
      loading: true
    }
  },

  mounted() {
    this.loadDeviceForJob({
      jobId: this.jobId,
      deviceId: this.deviceId,
    }).then(() => this.setCurrentStep({
      jobId: this.jobId,
      deviceId: this.deviceId,
      currentStep: this.$route.name,
    }));

    this.loadSymptomOneCodes()
        .then(() => this.loadSymptomCodesForDevice({
          jobId: this.jobId,
          deviceId: this.deviceId
        }))
        .then(() => {
          let self = this;
          setTimeout(() => {
            self.loading = false
          }, 800);
        });
  },

  computed: {
    ...mapGetters([
      "device",
      "currentSymptomCode",
      "isJobManager",
      "nextRepairStep",
      "prevRepairStep",
      "symptomOneCodes",
      "symptomTwoCodes",
      "symptomThreeCodes",
      "symptomCodeOne",
      "symptomCodeTwo",
      "symptomCodeThree",
      "job"
    ]),

    /** Check if footer should be active */
    footerActive() {
      return (this.currentSymptomCode === 'complete' || this.isJobManager)
    }
  },

  methods: {
    ...mapActions([
      "loadDeviceForJob",
      "loadSymptomCodesForDevice",
      "loadSymptomOneCodes",
      "setCurrentStep",
      "setSymptomCodeOne",
      "setSymptomCodeTwo",
      "setSymptomCodeThree",
      "editSymptomCodeOne",
      "editSymptomCodeTwo",
      "editSymptomCodeThree",
      "confirmSymptomCodes"
    ]),

    completeSymptomCodes(symptomThreeCode) {
      this.setSymptomCodeThree(symptomThreeCode)
          .then(() => {
            this.confirmSymptomCodes({
              jobId: this.jobId,
              deviceId: this.deviceId
            });
          });
    },
  }
}
</script>
